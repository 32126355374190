import React from "react";

export const SectionQuote = ({ quote, author }) => {
  return (
    <section className="bg-mud text-sand text-center">
      <div className="container px-6 py-20 sm:px-10 sm:py-32 max-w-4xl">
        <h3 className="text-3xl font-serif">{quote}</h3>
        {!!author && <p className="mt-2 sm:mt-4">~ {author} ~</p>}
      </div>
    </section>
  );
};
