import React from "react";
import { scrollTo } from "../../utils/ScrollTo";

export const Button = ({ className, scrollToId, children }) => {
  return (
    <button
      className={`inline-block py-2 px-4 uppercase rounded-sm ${className}`}
      onClick={scrollTo(scrollToId)}
    >
      {children}
    </button>
  );
};
