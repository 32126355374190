import React from "react";
import profile from "../profile.jpg";

export const AboutContent = () => {
  return (
    <div>
      <div className="sm:flex">
        <div className="sm:w-2/6 sm:pr-8">
          <img src={profile} alt="Noah Fernandes profile" className="rounded" />
        </div>
        <div className="sm:w-4/6 text-mud">
          <p className="text-3xl font-light font-serif mt-4 sm:mt-0">
            About Me: My Journey of Transformation
          </p>
          <p className="text-lg mt-4">
            Hi, I’m Noah Fernandes, a Facilitator of Transformational Healing &
            Growth. My mission is to guide you through emotional healing and
            personal growth, helping you create a life aligned with your
            authentic self.
          </p>
          <p className="mt-4">
            For most of my adult life, I sought answers to some of life’s most
            painful questions: Why do relationships hurt so much? Why does
            heartbreak cut so deep? My search for clarity began with my own
            experiences. A series of challenging relationships, including a
            painful divorce, left me questioning not just my choices but my
            sense of self.
          </p>
          <p className="mt-4">
            When I was 19, I attended a 10-day silent meditation retreat. It was
            a transformative experience that opened my eyes to a deeper truth:
            much of my suffering came from attachments and a lack of connection
            with my authentic self. Although life took me in many directions
            after that—moving between careers, cities, and relationships—that
            spark of self-awareness stayed with me.
          </p>
          <p className="mt-4">
            Years later, I found myself in couples therapy, trying to salvage my
            marriage. While the relationship didn’t survive, the experience
            awakened a deep fascination with the process of healing and
            understanding human emotions. I started reading, learning, and
            reflecting more deeply, discovering tools like mindfulness,
            breathwork, and the profound impact of music on emotional healing.
          </p>
          <p className="mt-4">
            This exploration revealed a powerful truth: the way we relate to
            others is a mirror of the way we relate to ourselves. Healing
            doesn’t come from outside validation—it starts within. This
            realisation didn’t just change my life; it gave me a purpose.
          </p>
          <p className="mt-4">
            I transitioned from a career in software programming to becoming a
            trauma-informed facilitator and coach. Along the way, I combined my
            love for music and creativity with evidence-based practices,
            creating a unique approach to emotional healing. Today, I work with
            individuals to help them navigate heartbreak, rebuild their sense of
            self-worth, and discover the strength that was within them all
            along.
          </p>
          <p className="mt-4">
            My journey has been one of transformation, and today, I’m thriving
            in ways I never thought possible. After navigating the pain of
            divorce and making a bold career shift, I’ve built a life rooted in
            authenticity, balance, and empowerment. Now, my mission is to walk
            alongside others on their own journeys toward healing and growth.
          </p>
          <p className="mt-4">
            Whether you're recovering from a challenging relationship, working
            through the complexities of trauma, or striving to feel more whole,
            I’m here to provide you with personalised support and guidance,
            every step of the way.
          </p>
          <a
            className="bg-mud text-sand mt-8 inline-block py-2 px-4 uppercase rounded-sm"
            href="https://calendly.com/noah-counselling/discovery-call"
            target="_new"
          >
            Book a free discovery call
          </a>
        </div>
      </div>
    </div>
  );
};
