import React, { useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

const testimonials = [
  {
    name: "Gaby",
    age: "26",
    location: "Netherlands",
    description:
      "Noah's warmth and understanding have made all the difference. Unlike my past experiences, his approach felt like talking to a trusted friend. I felt an instant connection with him, which made it easy to share openly. In a time when I needed support the most, Noah guided me through challenges, offering advice on communication and self-expression. His sessions encouraged me to slow down and really tune into my feelings and needs, helping me navigate life with more clarity and authenticity.",
  },
  {
    name: "Mona",
    age: "46",
    location: "Egypt",
    description:
      "This was my first experience with counselling, and it exceeded my expectations. I felt empowered to express myself freely, knowing I wouldn't be judged. Noah's support has been invaluable. He patiently addressed all my questions and concerns, showcasing his expertise and professional tools. With Noah's guidance, I gained clarity and a fresh perspective on various aspects of my life.",
  },
  {
    name: "Floortje",
    age: "43",
    location: "Netherlands",
    description:
      "I have found Noah to be an exceptionally professional coach. Despite seeking help for family issues in the past, I never gained the insights I did with Noah. Going into the coaching sessions with no expectations, I was immediately struck by his approach. Noah is deeply committed, empathetic, analytical, objective, reliable, and possesses strong communication skills. His guidance led me to profound insights and a deeper understanding of myself. I am grateful for his support and the new knowledge he has helped me uncover.",
  },
  {
    name: "Nishant",
    age: "34",
    location: "Netherlands",
    description:
      "Noah is an exceptional counsellor who truly made a positive impact on my counselling journey. He has an ability to listen attentively and ask insightful questions to have a deep understanding of my challenges. He breaks down my challenges into small points and identifies the root cause. This exercise has helped me a lot and I am really grateful for it.",
  },
  {
    name: "Millicent",
    age: "34",
    location: "Ghana",
    description:
      "Noah was very polite and exercised empathy throughout our communication making it a safe place for me to be myself. His coaching helped me to clarify my career path. Noah provided me with invaluable guidance and clarity regarding where I currently stand in my career, where I want to go, and the steps I need to take to get there.",
  },
  {
    name: "Meryem",
    age: "",
    location: "United States",
    description:
      "We had a short session with Noah when I felt stuck in my career journey (i.e. struggling to expand my personal practice). His questions -all to the point and quite challenging- helped me analyze the problem more clearly. With Noah, I felt heard and he had a great balance between communication and challenge. As a plus, he has a holistic view taking all the systems around you into account. I recommend this talented coach/counselor if you need an insightful view of your career journey.",
  },
];

export const TestimonialsContent = () => {
  const [current, setCurrent] = useState(0);

  const nextTestimonial = () => {
    setCurrent((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const prevTestimonial = () => {
    setCurrent((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  return (
    <div>
      <h3 className="text-center text-5xl font-serif mb-20">
        Words from Those I’ve Worked With
      </h3>
      <div className="mt-8">
        <div className="max-w-2xl m-auto text-sand rounded-3xl p-8 sm:p-16 sm:border bg-mud">
          <p className="text-6xl font-serif opacity-60">"</p>
          <p>{testimonials[current].description}</p>
          <p className="text-6xl font-serif text-right opacity-60">"</p>
          <p className="mt-8 text-2xl font-serif">
            {testimonials[current].name}
          </p>
          <p className="text-sm">{testimonials[current].location}</p>
        </div>

        <div className="flex justify-center mt-8">
          <button
            className="bg-mud text-sand py-2 px-4 rounded-full mx-2"
            onClick={prevTestimonial}
          >
            <FaArrowLeft size={24} />
          </button>
          <button
            className="bg-mud text-sand py-2 px-4 rounded-full mx-2"
            onClick={nextTestimonial}
          >
            <FaArrowRight size={24} />
          </button>
        </div>
      </div>
    </div>
  );
};
