import React from "react";

export const ContactContent = () => {
  return (
    <div className="text-sand p-10 sm:p-20">
      <div className="sm:flex">
        <div className="sm:w-1/3 pr-8">
          <h2 className="text-4xl font-serif">Contact information</h2>
        </div>
        <div className="sm:w-2/3 mt-4 sm:mt-0">
          <p>
            I’m here to support you on your journey of healing and growth. You
            are welcome to reach out to me anytime you seek my help.
          </p>
          <h3 className="mt-4 font-medium">Get in Touch</h3>
          <a
            href="mailto:noah.counselling@gmail.com"
            className="text-mud block"
          >
            noah.counselling@gmail.com
          </a>

          <h3 className="mt-4 font-medium">Book a Session (Mon, Tue, Wed)</h3>
          <a
            href="https://calendly.com/noah-counselling/discovery-call"
            target="_new"
            className="text-mud block"
          >
            Free 15-minute discovery call
          </a>
          <a
            href="https://calendly.com/noah-counselling/60-minute-session"
            target="_new"
            className="text-mud block"
          >
            60-minute session (€90)
          </a>
          <a
            href="https://calendly.com/noah-counselling/90-minute-session"
            target="_new"
            className="text-mud block"
          >
            90-minute session (€135)
          </a>

          <h3 className="mt-4 font-medium">Social Media</h3>
          <a
            href="https://www.instagram.com/your.transforming.journey/"
            target="_new"
            className="text-mud block"
          >
            Instagram
          </a>
          <a
            href="https://www.youtube.com/channel/UCQI1HBwXk0Ywu0wzNLW8kTQ"
            target="_new"
            className="text-mud block"
          >
            YouTube
          </a>
          <a
            href="https://www.linkedin.com/in/noah-fernandes/"
            target="_new"
            className="text-mud block"
          >
            LinkedIn
          </a>
        </div>
      </div>
    </div>
  );
};
