import React from "react";
import logo from "../logo.png";
import { Button } from "./common/Button";

export const HomeContent = () => {
  return (
    <div>
      <div className="mt-20">
        <img src={logo} className="w-1/4 m-auto" alt="Lotus Coconut Logo" />
        <div className="text-center mt-20 ax-w-xl m-auto w-3/5">
          <h1 className="text-4xl text-sand tracking-wider font-serif">
            Find Healing and Growth in a Safe and Empowering Space.
          </h1>
          <div className="mt-16">
            <Button
              className="bg-md border-solid border border-sand text-sand mr-4 inline-block py-2 px-4 uppercase rounded-sm"
              scrollToId="about"
            >
              My transforming journey
            </Button>
            <a
              className="bg-sand text-mud mt-12 inline-block py-2 px-4 uppercase rounded-sm"
              href="https://calendly.com/noah-counselling/discovery-call"
              target="_new"
            >
              Book a free discovery call
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
