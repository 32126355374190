import React from "react";
import { scrollTo } from "../../utils/ScrollTo";
import { content } from "../../data/content";
import { ReactComponent as InstagramIcon } from "../../icons/instagram.svg";
import { ReactComponent as YouTubeIcon } from "../../icons/youtube.svg";
import { ReactComponent as LinkedInIcon } from "../../icons/linkedin.svg";

export const AppHeader = () => {
  return (
    <header className="hidden lg:block absolute top-16 left-0 right-0">
      <div className="relative py-6 px-10 text-slate">
        <div className="absolute left-10 top-1/2 transform -translate-y-1/2 flex sm:space-x-8">
          <a
            href="https://www.instagram.com/your.transforming.journey/"
            target="_new"
          >
            <InstagramIcon className="w-6 fill-sand" />
          </a>
          <a
            href="https://www.youtube.com/channel/UCQI1HBwXk0Ywu0wzNLW8kTQ"
            target="_new"
          >
            <YouTubeIcon className="w-6 fill-sand" />
          </a>
          <a href="https://www.linkedin.com/in/noah-fernandes/" target="_new">
            <LinkedInIcon className="w-6 fill-sand" />
          </a>
        </div>

        <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center leading-none">
          <h1 className="text-4xl text-sand font-serif">{content.name}</h1>
          <span className="text-xl font-light text-sand">{content.role}</span>
        </div>

        <div className="absolute right-10 top-1/2 transform -translate-y-1/2 flex sm:space-x-8">
          <button
            className="hover:text-mud text-sand"
            onClick={scrollTo("home")}
          >
            Home
          </button>
          <button
            className="hover:text-mud text-sand"
            onClick={scrollTo("about")}
          >
            About
          </button>
          <button
            className="hover:text-mud text-sand"
            onClick={scrollTo("services")}
          >
            Services / Fees
          </button>
          <button
            className="hover:text-mud text-sand"
            onClick={scrollTo("testimonials")}
          >
            Testimonials
          </button>
          <button
            className="hover:text-mud text-sand"
            onClick={scrollTo("contact")}
          >
            Contact
          </button>
        </div>
      </div>
    </header>
  );
};
