import { AboutContent } from "./components/AboutContent.jsx";
import { AppFooter } from "./components/common/AppFooter.jsx";
import { AppHeader } from "./components/common/AppHeader.jsx";
import { AppSection } from "./components/common/AppSection.jsx";
import { HomeContent } from "./components/HomeContent.jsx";
import { SectionQuote } from "./components/SectionQuote.jsx";
import { ServicesContent } from "./components/ServicesContent.jsx";
import { TestimonialsContent } from "./components/TestimonialsContent.jsx";
import { ContactContent } from "./components/ContactContent.jsx";

import "./App.css";

function App() {
  return (
    <div className="App w-screen h-screen bg-sand overflow-auto relative">
      <AppHeader />

      <AppSection
        id="home"
        className="bg-gradient-to-r from-red-500 to-orange-500 flex items-center"
      >
        <HomeContent />
      </AppSection>

      <SectionQuote
        quote="The wound is the place where the Light enters you."
        author="Rumi"
      />

      <AppSection id="about">
        <AboutContent />
      </AppSection>

      <AppSection id="services" className="bg-mud text-sand">
        <ServicesContent />
      </AppSection>

      <AppSection id="testimonials">
        <TestimonialsContent />
      </AppSection>

      <AppSection
        id="contact"
        className="bg-gradient-to-r from-red-500 to-orange-500 "
      >
        <ContactContent />
      </AppSection>

      <AppFooter />
    </div>
  );
}

export default App;
